<template>
  <main id="content" role="main">

    <!-- Hero Section -->
    <div class="position-relative bg-img-hero overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/svg/components/general-bg-1.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2 position-relative">
        <div class="w-md-80 w-xl-60 text-center mx-md-auto">
          <div class="mb-7">
            <h1 class="mb-4">Press Room</h1>
            <p class="lead mb-4">News and updates about apidays Hong Kong</p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Hero Section -->

    <div class="container space-2">
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <span class="d-block small font-weight-bold text-cap mb-2">Press Coverage</span>
        <h2>Latest News</h2>
      </div>
      <a v-for="item in block_item_list" :key="item.title" class="row align-items-lg-center mb-4 transition-3d-hover" :href="item.url" target="_blank" data-gtm-att="ga-press-latest-news-card">
        <div class="col-lg-6">
          <img class="img-fluid rounded" :src="require('@/assets/img/press/' + item.image_url)">
        </div>
        <div class="col-lg-6">
          <div class="card-header border-0 pb-0">
            <p>{{ item.date }}</p>
          </div>
          <div class="card-body">
            <h3 class="mb-3">{{ item.title }}</h3>
            <p class="lead">{{ item.organization }}</p>
          </div>
          <div class="card-footer border-0 pt-0">
            <a :href="item.url" target="_blank" class="font-weight-bold">Read more <i class="fas fa-angle-right fa-sm ml-1" /></a>
          </div>
        </div>
      </a>
    </div>

    <div class="container">
      <hr>
    </div>

    <div class="container space-2 space-bottom-lg-3" data-aos="fade-up" data-aos-delay="250">
      <div class="w-md-80 w-lg-90 text-center mx-md-auto mb-5 mb-md-9">
        <h2>More of apidays Hong Kong in the News</h2>
      </div>
      <div class="row">
        <div v-for="(item, index) in press_list" :key="item.title" class="news col-sm-6 col-lg-4 mb-4" :style="index <= 5 ? '' : 'display: none'">
          <PressCard :item="item" />
        </div>
      </div>
      <div class="col-12 text-center">
        <button id="loadMoreNews" class="btn btn-primary transition-3d-hover mt-3 mt-md-5" data-gtm-att="ga-press-show-more-button" @click="showAllPressCard()">Show More</button>
      </div>
    </div>

  </main>
</template>

<script>
const $ = require('jquery')
import PressCard from '@/components/press-card'
import HSGoTo from '../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../assets/js/hs.core.js'
import '../../assets/js/hs.slick-carousel.js'
import '../../assets/vendor/slick-carousel/slick/slick.js'
export default {
  name: 'Press',
  components: {
    PressCard
  },
  data() {
    return {
      block_item_list: [
        {
          url: 'https://unwire.pro/2023/09/04/benovelty-2/opendata/',
          image_url: 'unwire-20230904.jpg',
          organization: 'Unwire.pro',
          title: '科技園與 beNovelty 攜手推動 API 技術產品化',
          date: 'September 04, 2023'
        },
        {
          url: 'https://itpromag.com/2023/08/31/hkstp-apidays-2023/',
          image_url: 'itpro-20230831.jpg',
          organization: 'IT Pro Magazine',
          title: '香港科技園構建API生態系統 開放金融具發展潛力',
          date: 'August 31, 2023'
        },
        {
          url: 'https://www.hkstp.org/news-room/hkstp-and-benovelty-host-apidays-connect-hong-kong-2023-to-boost-digital-economy-development/',
          image_url: 'hkstp-20230831.jpg',
          organization: 'HKSTP',
          title: 'HKSTP and beNovelty Host Apidays Connect Hong Kong 2023 to Boost Digital Economy Development',
          date: 'August 31, 2023'
        }
      ],
      press_list: [
        {
          url: 'https://www.hkstp.org/zh-hk/news-room/hkstp-and-benovelty-host-apidays-connect-hong-kong-2023-to-boost-digital-economy-development/',
          image_url: 'press-bg-1.svg',
          organization: '香港科技園',
          title: '香港科技園公司與彼樂科創有限公司合辦 Apidays Connect Hong Kong 2023 攜手推動數字經濟發展',
          date: 'August 31, 2023'
        },
        {
          url: 'https://www.hkstp.org/zh-cn/news-room/hkstp-and-benovelty-host-apidays-connect-hong-kong-2023-to-boost-digital-economy-development/',
          image_url: 'press-bg-3.svg',
          organization: '香港科技園',
          title: '香港科技园公司与彼乐科创有限公司合办 Apidays Connect Hong Kong 2023 携手推动数字经济发展',
          date: 'August 31, 2023'
        },
        {
          url: 'https://www.etnet.com.hk/www/tc/news/mediaoutreach_news_detail.php?newsid=155865&page=1',
          image_url: 'press-bg-4.svg',
          organization: '經濟通 ET Net',
          title: 'Apidays香港站2022：深入探討開放API 及平台經濟生態',
          date: 'August 11, 2022'
        },
        {
          url: 'https://sg.finance.yahoo.com/news/apidays-hong-kong-2021-deep-071000247.html',
          image_url: 'press-bg-6.svg',
          organization: 'Yahoo Finance',
          title: 'Apidays Hong Kong 2021: Deep Diving the Open API, Open Banking & Data Ecosystem in Hong Kong',
          date: 'August 17, 2021'
        },
        {
          url: 'http://www.etnet.com.hk/www/tc/news/mediaoutreach_news_detail.php?newsid=90518&page=1',
          image_url: 'press-bg-5.svg',
          organization: '經濟通 ET Net',
          title: 'Apidays香港站2021 : 深入探討香港的開放 API、開放銀行和數據生態圈',
          date: 'August 17, 2021'
        },
        {
          url: 'https://www.hkstp.org/press-room/hkstp-and-benovelty-spark-digital-economy-drive-with-apidays-live-hong-kong/',
          image_url: 'press-bg-2.svg',
          organization: 'HKSTP',
          title: 'HKSTP and beNovelty Spark Digital Economy Drive with Apidays LIVE HONG KONG',
          date: 'November 4, 2020'
        },
        {
          url: 'http://www.etnet.com.hk/www/tc/news/prnewswire_news_detail.php?newsid=3134052_XG34052_2',
          image_url: 'press-bg-4.svg',
          organization: '經濟通 ET Net',
          title: '本地科技初創beNovelty主辦全港首個國際大型API活動「Apidays」',
          date: 'September 23, 2020'
        }
      ]
    }
  },
  computed: {
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
    this.loadScriptBenGeneralForm('https://js.hsforms.net/forms/v2.js', () => {
      const secondScript = document.createElement('script')
      secondScript.innerHTML = `
        hbspt.forms.create({
          portalId: "7091000",
          formId: "13239e83-63b2-4469-8e47-aaf91bdc82d2"
        });
      `
      this.$refs.benGeneralForm.appendChild(secondScript)
    })
  },
  methods: {
    showAllPressCard() {
      $('.news:hidden').fadeIn('slow')
      $('#loadMoreNews').fadeOut('slow')
    },
    loadScriptBenGeneralForm(url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onreadystatechange = callback
      script.onload = callback
      this.$refs.benGeneralForm.appendChild(script)
    }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Press Room | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/press' }
      ],
      meta: [
        { name: 'description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Press Room | beNovelty' },
        { property: 'og:description', content: 'News and updates about beNovelty and the API community.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/press' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>

